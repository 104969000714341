/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  Variables
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
const spsize = 767;



/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  Mobile check
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
function isSp () {
  return (window.innerWidth <= spsize) ? true : false;
}


/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  AOS
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
if($('body').hasClass('top')) {
  AOS.init({
    duration: 1000
  });
}


/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  Slider
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
if($('body').hasClass('top')) {
  var mySwiper = new Swiper ('.swiper-container', {
    loop: true,
    speed: 800,
    slidesPerView: 3,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 2000,
    },
  })
}


/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  Smooth Scroll
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
$('.js-top').click(function() {
  $('html, body').animate({scrollTop:0});
  return false;
});


/*୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧
  Scroll Check
  ୨୧┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈┈୨୧*/
let scrolltop = 0;
const target = document.body;
// const footer_pos = $('.fnav').offset().top;
let footer_hei = $('.fnav').outerHeight() + $('.footer').outerHeight();
let body_hei = $('body').height();
let footer_pos = body_hei - footer_hei - ($(window).height() / 2);

window.addEventListener('scroll', onScroll);

function onScroll() {
  scrolltop = window.pageYOffset;
  footer_hei = $('.fnav').outerHeight() + $('.footer').outerHeight();
  body_hei = $('body').height();
  footer_pos = body_hei - footer_hei - ($(window).height() / 2);
  if(scrolltop == 0 || (isSp() && scrolltop > footer_pos)) {
    target.classList.remove('is-scrolled');
  } else {
    target.classList.add('is-scrolled');
  }
}
